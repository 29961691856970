var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Type of document'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' cédula'},model:{value:(_vm.objLegalDocument.document_type),callback:function ($$v) {_vm.$set(_vm.objLegalDocument, "document_type", $$v)},expression:"objLegalDocument.document_type"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('State'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objLegalDocument.document_status),callback:function ($$v) {_vm.$set(_vm.objLegalDocument, "document_status", $$v)},expression:"objLegalDocument.document_status"}},_vm._l((_vm.documentStatus),function(option){return _c('option',{key:option.id,domProps:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date submitted'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objLegalDocument.date_presented),callback:function ($$v) {_vm.$set(_vm.objLegalDocument, "date_presented", $$v)},expression:"objLegalDocument.date_presented"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Comment'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + ' Presento el documento...'},model:{value:(_vm.objLegalDocument.comment),callback:function ($$v) {_vm.$set(_vm.objLegalDocument, "comment", $$v)},expression:"objLegalDocument.comment"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-start"},[_c('label',{staticClass:"label is-align-self-flex-start",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Document')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"file",class:{ 'has-name': !!_vm.objLegalDocument.upload },attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-upload',{staticClass:"file-label",on:{"input":function($event){return _vm.fileChange()}},model:{value:(_vm.objLegalDocument.upload),callback:function ($$v) {_vm.$set(_vm.objLegalDocument, "upload", $$v)},expression:"objLegalDocument.upload"}},[_c('span',{staticClass:"file-cta"},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"upload"}}),_c('span',{staticClass:"file-label"},[_vm._v(_vm._s(_vm.$t('Upload document')))])],1),(_vm.objLegalDocument.upload)?_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.objLegalDocument.upload.name)+" ")]):_vm._e()])],1)]}}],null,true)})],1)])]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v(" Enviar ")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }